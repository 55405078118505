import Vue from "vue";
import VueRouter from "vue-router";
import Art from "../views/Art.vue";
import Artist from "../views/Artist.vue";
import Artists from "../views/Artists.vue";
import Covers from "../views/Covers.vue";
import Faq from "../views/Faq.vue";
import Home from "../views/Home.vue";
import Marketplace from "../views/Marketplace.vue";
import Mint from "../views/Mint.vue";
import SingleNft from "../views/SingleNft.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/cross",
    name: "Marketplace",
    component: Marketplace,
  },
  {
    path: "/cross/:contract/:tokenId",
    name: "SingleNft",
    component: SingleNft,
  },
  {
    path: "/covers",
    name: "Covers",
    component: Covers,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/mint",
    name: "Mint",
    component: Mint,
  },
  {
    path: "/artist/:artistId",
    name: "Artist",
    component: Artist,
  },
  {
    path: "/artists",
    name: "Artists",
    component: Artists,
  },
  {
    path: "/vault/:tokenId",
    name: "Art",
    component: Art,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
