<template>
  <div class="mint">
    <div class="sync" v-if="!account">
      <h1 class="title is-3">Connect your wallet.</h1>
      <p>You'll need to sync and authenticate yourself by signing a message.</p>
      <b-button v-if="!synced" @click="sync">SYNC</b-button>
      <b-button v-if="synced" @click="authenticate">AUTHENTICATE</b-button>
    </div>
    <div v-if="account">
      <div class="synced">
        {{ artist.name }} | {{ account }}
        <b-button @click="unsync">UNSYNC</b-button>
      </div>
      <div v-if="!prepared">
        <b-field label="Title">
          <b-input
            placeholder="write here the title of your NFT.."
            v-model="name"
          ></b-input>
        </b-field>
        <b-field label="Tags">
          <b-input
            placeholder="write here your tags (comma separated).."
            v-model="tags"
          ></b-input>
        </b-field>
        <b-field label="Description">
          <b-input
            maxlength="800"
            placeholder="write here the description of your nft.."
            v-model="description"
            type="textarea"
          ></b-input>
        </b-field>
        <b-field v-if="!fileToMint.name" label="Ipfs Media">
          <b-input
            placeholder="put your IPFS media string.."
            v-model="ipfsMedia"
          ></b-input>
        </b-field>
        <b-field
          v-if="!fileToMint.name && ipfsMedia.length === 0"
          style="margin-top: 30px; margin-bottom: 0 !important"
        >
          <b-upload v-model="fileToMint" expanded drag-drop>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  Drop your files here or click to upload.<br />Supported files:
                  jpg, mp4, png, gif.
                </p>
              </div>
            </section>
          </b-upload>
        </b-field>
        <div v-if="fileToMint.name" style="margin-top: 30px">
          <div v-if="!show" @click="show = true">
            <b-icon
              icon="eye"
              style="float: left; cursor: pointer"
              size="is-small"
            ></b-icon>
          </div>
          <div v-if="show" @click="show = false">
            <b-icon
              icon="eye-off"
              style="float: left; cursor: pointer"
              size="is-small"
            ></b-icon>
          </div>
          Selected file: <b>{{ fileToMint.name }}</b>
          <div
            @click="fileToMint = {}"
            style="float: right; cursor: pointer; font-size: 18px"
          >
            X
          </div>
          <br /><br />
          <div v-if="preview && show">
            <img :src="preview" width="100%" />
          </div>
        </div>
        <b-button
          v-bind:disabled="
            name.length === 0 ||
            (fileToMint.name === undefined && !ipfsMedia) ||
            (fileToMint.name !== undefined && fileToMint.name.length === 0) ||
            description.length === 0
          "
          v-if="!isPreparing && !prepared"
          style="margin: 80px 0"
          @click="prepare"
          >PREPARE METADATA</b-button
        >
      </div>
      <div v-if="prepared" class="sync">
        Your metadata are ready,<br />please double check if everything is
        correct by following this link:<br />
        <a :href="'https://ipfs.yomi.digital/ipfs/' + prepared" target="_blank">{{
          prepared
        }}</a
        ><br /><br />
        <hr />
        <br />
      </div>
      <b-button
        v-if="!isMinting && prepared && !contractOpened"
        style="margin: 30px 0 50px 0"
        @click="contractOpened = true"
        >PROCEED TO CONTRACT</b-button
      >
      <div v-if="isPreparing">Preparing metadata, please wait..</div>
    </div>
    <b-modal
      v-model="contractOpened"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      close-button-aria-label="Close"
      aria-modal
    >
      <template>
        <div class="modal-card contract-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">FAKEWHALE WEBSITE TERMS OF SERVICE</p>
          </header>
          <section class="modal-card-body">
            <b>Introduction:</b><br /><br />FakeWhale is a collective of
            artists, collectors and entrepreneurs, which aims to create a
            curated digital artistic vault composed of selected artworks within
            the highly intricate system of Non-Fungible Tokens (“NFT”) digital
            art (the “FakeWhale Vault”) with the mission to elevate those
            artists whom FakeWhale considers the most influential (presently or
            in the future) within the space.<br /><br />This website is operated
            by FakeWhale Sagl, owner of Fakewhale.xyz. Throughout the site, the
            terms “we”, “us” and “our” refer to “FakeWhale”. FakeWhale offers
            this website, including all information, tools and services
            available from this site to you, the user, conditioned upon your
            acceptance of all terms, conditions, policies and notices stated
            here.<br /><br />By using our website, you engage in our “Service”
            and agree to be bound by the following terms and conditions (“Terms
            of Service”, “Terms”). If you do not agree to all the terms and
            conditions of this agreement, then you may not access the website or
            use any services. If these Terms of Service are considered an offer,
            acceptance is expressly limited to these Terms of Service.<br /><br />Any
            new features or tools which are added to the current site shall also
            be subject to the Terms of Service. You can review the most current
            version of the Terms of Service at any time on this page. We reserve
            the right to update, change or replace any part of these Terms of
            Service by posting updates and/or changes to our website. It is your
            responsibility to check this page for any changes upon your usage of
            the Services. Your continued use of or access to the website
            following the posting of any changes constitutes acceptance of those
            changes. For the sake of clarity, any change in these Terms of
            Service shall only affect the Terms from the moment of the change,
            and will not impact any past usage of the website and the
            Services.<br /><br /><b>Minting and Transfer Terms:</b
            ><br /><br />By using this website you can mint your artwork into an
            NFT, artwork which you warrant is unique and original, fully owned
            by you, clear of any claims or encumbrances, and does not infringe
            on the rights of any third parties. In particular, your artwork will
            be minted by you on the Smart Contract provided by FakeWhale (the
            “FakeWhale Smart Contract”). By using the FakeWhale Smart Contract,
            the NFT that is minted will be directly, irrevocably, free of
            charge, transferred into the designated wallet of FakeWhale as part
            of the FakeWhale Vault. You thereby assign and transfer to FakeWhale
            the irrevocable, exclusive, full and entire ownership of the
            relevant NFT minted through this process (the “Minting and
            Transfer”). By undergoing the Minting and Transfer, all rights to
            use in a commercial or non-commercial manner the NFT shall be
            exclusively and irrevocably transferred to FakeWhale.<br /><br />Save
            for the promotion and display of the NFT on your own media (i.e.,
            social media, website, self-promotion, documentation etc.), which
            may be at your discretion, any further use of the NFT by you has to
            be communicated to and is subject to the sole approval of FakeWhale
            in advance.<br /><br />FakeWhale Mission is building a curated
            collection of NFT artworks, the FakeWhale Vault, for the purpose of
            creating a unique portfolio that is optimally suited to popularize
            crypto-art and artists, thereby increasing the reach, reputation and
            value of the FakeWhale Vault, the chosen artists and their
            respective artworks.<br /><br />You agree to contribute the selected
            NFT minted through the Minting and Transfer process to be a part of
            the FakeWhale Vault under these Terms of Service and thereby benefit
            from the activities that FakeWhale will undertake as part of its
            mission.<br /><br /><b>General Terms:</b><br /><br />You must have
            an Artist Page to use the Services, which we will create for you.
            Before you are able to use the Services, you need to provide us with
            your electronic wallet public address, which we will whitelist. that
            you will use to be connected to the website and that will be
            associated with your Artist Page. You will only be recognised as a
            user and allowed to benefit from the website and the Service, if the
            electronic wallet that you indicated to us is used by you to be
            connected to the website. No other means are available to sign up to
            the website or use the Services.<br /><br />We may, in our sole
            discretion, refuse, decline, suspend or disable your access or use
            of the website for any reasonable justification.<br /><br />You
            understand and agree that you are solely responsible for maintaining
            the security and control over the electronic wallet that you use to
            access the Services. You understand and agree that you will not hold
            us responsible for managing and maintaining the security of your
            electronic wallet. You further understand and agree that we are not
            responsible (and you will not hold us responsible) for any
            unauthorized access to or use of your account. <br /><br />You agree
            to defend, indemnify, and hold FakeWhale harmless against all costs,
            expenses, and losses incurred through claims of third parties
            against FakeWhale based on a breach by you of any provision in these
            Terms of Service.<br /><br />These Terms are governed by and shall
            be construed in accordance with the laws of Switzerland without
            regard to any choice or conflict of laws rules. Any dispute,
            controversy, or claim, whether contractual or non-contractual,
            arising out of or in connection with these Terms, or the breach,
            termination or invalidity thereof, or any other issue which shall
            arise in virtue of these Terms, shall be referred to and finally
            settled by arbitration administered by the ICC International Court
            of Arbitration under the rules in force when the notice of
            arbitration is submitted. The law of this arbitration clause shall
            be Swiss law. The seat of arbitration shall be in Switzerland The
            number of arbitrators shall be one. The arbitration proceedings
            shall be conducted in the English language.<br /><br />
            <div v-if="isSigning">Please sign message to in your wallet..</div>
            <div v-if="isMinting">{{ mintingState }}</div>
            <b-button
              v-if="!isSigning && !contractSigned"
              style="margin: 30px 0 50px 0"
              @click="signContract"
              >SIGN CONTRACT</b-button
            >
            <b-button
              v-if="!isMinting && prepared && contractSigned"
              style="margin: 30px 0 50px 0"
              @click="mint"
              >MINT NFT</b-button
            >
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { NetworkType, SigningType } from "@airgap/beacon-sdk";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { MichelsonMap, TezosToolkit } from "@taquito/taquito";
import { char2Bytes } from "@taquito/utils";
import axios from "axios";
const Tezos = new TezosToolkit(process.env.VUE_APP_TOOKLIT_ENDPOINT);
const beacon = new BeaconWallet({ name: "Fakewhale" });
const sizeOf = require("buffer-image-size");
const FormData = require("form-data");
Tezos.setWalletProvider(beacon);

export default {
  name: "Mint",
  data() {
    return {
      account: "",
      pubKey: "",
      synced: "",
      artist: {},
      fileToMint: {},
      name: "",
      preview: "",
      description: "",
      ipfsMedia: "",
      tags: "",
      show: false,
      axios: axios,
      contractOpened: false,
      isPreparing: false,
      isSigning: false,
      isMinting: false,
      contractSigned: false,
      prepared: "",
      mintingState: "Minting nft, please wait..",
      lambdaURL: process.env.VUE_APP_LAMBDA_URL,
    };
  },
  async mounted() {
    const app = this;
    const cache = localStorage.getItem("tz_account");
    if (cache !== null) {
      try {
        app.artist = JSON.parse(localStorage.getItem("tz_artist"));
        app.account = localStorage.getItem("tz_account");
        app.pubKey = localStorage.getItem("tz_pubkey");
      } catch (e) {
        localStorage.removeItem("tz_account");
        localStorage.removeItem("tz_pubkey");
        localStorage.removeItem("tz_artist");
      }
    }
  },
  watch: {
    fileToMint() {
      try {
        this.preview = URL.createObjectURL(this.fileToMint);
      } catch (e) {
        this.preview = "";
      }
    },
  },
  methods: {
    async sync() {
      const app = this;
      try {
        console.log("Asking permissions...");
        const permissions = await beacon.client.requestPermissions({
          network: { type: NetworkType.MAINNET },
        });
        console.log("Got permissions:", permissions);
        app.synced = permissions.address;
        app.pubKey = permissions.publicKey;
      } catch (error) {
        console.log("Got error:", error);
      }
    },
    async authenticate() {
      const app = this;
      try {
        const toSign = await app.axios.post(app.lambdaURL + "/challenge", {
          message: "Authenticate me as Fakewhale artist.",
        });
        const response = await beacon.client.requestSignPayload({
          signingType: SigningType.MICHELINE,
          payload: toSign.data.toSign,
        });
        const authorization = await app.axios.post(
          app.lambdaURL + "/artists/auth",
          {
            message: toSign.data.toSign,
            public_key: app.pubKey,
            signature: response.signature,
          }
        );
        if (authorization.data.message !== undefined) {
          app.$buefy.snackbar.open(authorization.data.message);
          if (!authorization.data.error) {
            app.account = app.synced;
            app.artist = authorization.data.artist;
            localStorage.setItem("tz_account", app.account);
            localStorage.setItem("tz_pubkey", app.pubKey);
            localStorage.setItem("tz_artist", JSON.stringify(app.artist));
          }
        }
      } catch (e) {
        app.$buefy.snackbar.open(
          "Authentication endpoint failed, please retry!"
        );
      }
    },
    async signContract() {
      const app = this;
      if (!app.isSigning) {
        app.isSigning = true;
        try {
          const toSign = await app.axios.post(app.lambdaURL + "/challenge", {
            message: "I accept the Terms & Conditions of fakewhale.xyz",
          });
          const response = await beacon.client.requestSignPayload({
            signingType: SigningType.MICHELINE,
            payload: toSign.data.toSign,
          });
          console.log("Message:", toSign.data.toSign);
          console.log("PubKey:", app.pubKey);
          console.log("Signature:", response.signature);
          app.contractSigned = true;
          app.isSigning = false;
        } catch (e) {
          app.$buefy.snackbar.open(
            "Authentication endpoint failed, please retry!"
          );
          app.isSigning = false;
        }
      }
    },
    readFile(file, ext) {
      return new Promise((response) => {
        var reader = new FileReader();
        reader.onload = function (event) {
          var readed = event.target.result;
          if (ext !== "mp4") {
            var dimensions = sizeOf(Buffer.from(readed));
            response(dimensions);
          } else {
            response(false);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async prepare() {
      const app = this;
      if (!app.isPreparing) {
        app.isPreparing = true;
        if (
          app.name !== undefined &&
          app.description !== undefined &&
          app.name.length > 0 &&
          app.description.length > 0 &&
          ((app.fileToMint.name !== undefined &&
            app.fileToMint.name.length > 0) ||
            (app.ipfsMedia !== undefined && app.ipfsMedia.length > 0))
        ) {
          let ext;
          let ipfs_string;
          let analyzed = false;
          let format;
          if (app.ipfsMedia.length === 0) {
            ext =
              app.fileToMint.name.split(".")[
                app.fileToMint.name.split(".").length - 1
              ];
            console.log("Extension file is:", ext);
            const supported = ["mp4", "gif", "png", "jpg", "jpeg"];
            if (supported.indexOf(ext) !== -1) {
              try {
                console.log("Uploading using UMi..");
                const formData = new FormData();
                formData.append("file", app.fileToMint);
                console.log("File to upload:", app.fileToMint);
                formData.append("name", new Date().getTime() + "." + ext);
                const media = await axios({
                  method: "post",
                  url: process.env.VUE_APP_UMI_API + "/ipfs/upload",
                  data: formData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });
                console.log(media.data);
                if (
                  media.data !== undefined &&
                  media.data.error === false &&
                  media.data.ipfs_hash !== undefined
                ) {
                  ipfs_string = media.data.ipfs_hash;
                  console.log("IPFS media is: " + ipfs_string);
                } else {
                  app.isPreparing = false;
                  app.$buefy.snackbar.open(
                    `IPFS media failed, file seems invalid.`
                  );
                }
              } catch (e) {
                app.isPreparing = false;
                app.$buefy.snackbar.open(
                  `IPFS media failed, file seems invalid.`
                );
              }
            } else {
              app.$buefy.snackbar.open(`File not supported, retry.`);
            }
            analyzed = await app.readFile(app.fileToMint, ext);
            format = {
              uri: "ipfs://" + ipfs_string.replace("/ipfs/", ""),
              mimeType: app.fileToMint.type,
              size: app.fileToMint.size,
              fileName: app.fileToMint.name,
            };
            console.log("Analyzed:", analyzed);
          } else {
            ipfs_string = app.ipfsMedia;
          }
          if (ipfs_string.length > 0) {
            const toSign = await app.axios.post(app.lambdaURL + "/challenge", {
              message: "Create nft metadata for my nft.",
            });
            console.log("SIGN_REQUEST", toSign.data);
            const response = await beacon.client.requestSignPayload({
              signingType: SigningType.MICHELINE,
              payload: toSign.data.toSign,
            });
            let nftMetadata = {
              message: toSign.data.toSign,
              public_key: app.pubKey,
              signature: response.signature,
              media_ipfs: ipfs_string,
              title: app.name,
              description: app.description,
              tags: app.tags.replace(/\s/g, "").split(","),
              format,
            };
            if (analyzed !== false) {
              nftMetadata.format.dimensions = {
                value: analyzed.width + "x" + analyzed.height,
                unit: "px",
              };
            }
            console.log("PREPARING_REQUEST", nftMetadata);
            const nft = await app.axios.post(
              app.lambdaURL + "/nfts/create",
              nftMetadata
            );
            console.log("PREPARATION_RESPONSE", nft.data);
            if (
              nft.data.metadata !== undefined &&
              nft.data.metadata.ipfs !== undefined
            ) {
              app.prepared = nft.data.metadata.ipfs;
              app.isPreparing = false;
            } else {
              app.$buefy.snackbar.open(`Error while creating metadata, retry.`);
              app.isPreparing = false;
            }
          }
        } else {
          app.$buefy.snackbar.open(`Please fill all required fields.`);
        }
      }
    },
    async mint() {
      const app = this;
      if (!app.isMinting) {
        app.isMinting = true;
        const activeAccount = await beacon.client.getActiveAccount();
        if (activeAccount) {
          try {
            console.log(
              "Creating contract instance at " +
                process.env.VUE_APP_CONTRACT_ADDRESS
            );
            const contract = await Tezos.wallet.at(
              process.env.VUE_APP_CONTRACT_ADDRESS
            );
            console.log("Starting minting request..");
            console.log("Account is:", app.account);
            const minted = await app.axios.get(
              process.env.VUE_APP_TZKT_ENPOINT +
                "/tokens/transfers/count?token.contract=" +
                process.env.VUE_APP_CONTRACT_ADDRESS +
                "&to=" +
                app.account
            );
            console.log("Account minted:", minted.data);
            let nextId =
              app.artist.tokenBase.toString() +
              (parseInt(minted.data) + 1).toString();
            console.log("Next token id is:", nextId);
            const tokens = [
              {
                token_id: nextId,
                token_info: new MichelsonMap(),
              },
            ];
            tokens[0].token_info.set("", char2Bytes("ipfs://" + app.prepared));
            const minting = await contract.methods
              .mint([{ owner: app.account, tokens: tokens }])
              .send();
            await minting.confirmation();
            app.$buefy.snackbar.open(
              `Nft minted correctly, now transfer to collection.`
            );
            app.mintingState = "Transferring, please wait..";
            const transfers = [
              {
                to_: process.env.VUE_APP_FAKEWHALE_ADDRESS,
                token_id: nextId,
                amount: 1,
              },
            ];
            const transfer = await contract.methods
              .transfer([{ from_: app.account, txs: transfers }])
              .send();
            await transfer.confirmation();
            app.$buefy.snackbar.open(`Minting complete!`);
            app.isMinting = false;
            app.name = "";
            app.description = "";
            app.tags = "";
            app.fileToMint = {};
            app.mintingState = "Minting nft, please wait..";
            app.prepared = "";
            app.axios.get(app.lambdaURL + "/daemon/scan/" + app.account);
          } catch (error) {
            console.log(error);
            app.isMinting = false;
          }
        } else {
          app.$buefy.snackbar.open(`Can't get active account, unsyncing.`);
          app.unsync();
        }
      }
    },
    async unsync() {
      const app = this;
      app.account = "";
      localStorage.removeItem("tz_account");
      localStorage.removeItem("tz_artist");
      localStorage.removeItem("tz_pubkey");
    },
  },
};
</script>
