<template>
  <div v-if="!isMobile" class="nav">
    <div class="left-section">
      <div class="nav-logo">
        <a href="/">
          <img src="/logo.png" class="logo" />
        </a>
      </div>
    </div>
    <div class="cross">
      <a v-if="isCross" href="/#/cross"><img src="/cross.png" /></a>
      <a v-if="isArtists" href="/#/artists"
        ><img style="transform: scale(1.1)" class="pt-2" src="/artists.svg"
      /></a>
      <a v-if="isVault" href="/"><img src="/vault.png" /></a>
    </div>
    <div v-if="!userStore.synced">
     
      <a
        v-if="!isCross"
        :class="isTablet ? 'mr-3' : 'mr-5'"
        class="nav-marketplace secondary-font"
        href="/#/cross"
        >CROSS</a
      >
      <a v-else :class="isTablet ? 'mr-3' : 'mr-5'" class="nav-marketplace secondary-font" href="/#/">VAULT</a>
      <a :class="isTablet ? 'mr-3' : 'mr-5'" class="nav-marketplace secondary-font" href="/#/artists"
        >ARTISTS</a
      >
      <a
       
        :class="isTablet ? 'mr-3' : 'mr-5'"
        class="nav-marketplace secondary-font"
        href="https://log.fakewhale.xyz/" target=”_blank”
        >LOG</a
      >
      <my-button
        class="nav-sync-btn"
        @clicked="userStore.sync"
        :disabled="userStore.syncing"
        >SYNC</my-button
      >
    </div>
    <div v-if="userStore.synced" class="right-section">
     

      <a
        v-if="!isCross"
        class="nav-marketplace secondary-font mr-5"
        href="/#/cross"
        >CROSS</a
      >
      <a v-else class="nav-marketplace secondary-font mr-5" href="/#/">VAULT</a>
      <a class="nav-marketplace secondary-font mr-5" href="/#/artists"
        >ARTISTS</a
      >
      <a
       
        
      class="nav-marketplace secondary-font mr-5"
        href="https://log.fakewhale.xyz/" target=”_blank”
        >LOG</a
      >
      <span class="mr-4">{{ addressShort }}</span>
      <button @click="userStore.unsync" class="unsync-btn">
        <img src="/unsync.svg" />
      </button>
    </div>
  </div>
  <div v-else class="nav nav-mobile">
    <div class="rows">
      <div class="row nav-mobile-row mb-3 is-relative">
        <div class="nav-logo">
          <a href="/">
            <img src="/logo.png" class="logo" />
          </a>
        </div>
        <div v-if="!isMobile">
          <my-button
            class="nav-sync-btn"
            @clicked="userStore.sync"
            :disabled="userStore.syncing"
            v-if="!userStore.synced"
            >SYNC</my-button
          >
          <div v-if="userStore.synced" class="right-section">
            <span class="mr-4">{{ addressShort }}</span>
            <button @click="userStore.unsync" class="unsync-btn">
              <img src="/unsync.svg" />
            </button>
          </div>
        </div>
        <div class="cross">
          <a v-if="isCross" href="/#/cross"><img src="/cross.png" /></a>
          <a v-else href="/"><img src="/vault.png" /></a>
        </div>
        <div class="menu-toggle" :class="{ open: isOpen }" @click="toggleMenu">
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <div
          v-if="showMenu"
          class="is-flex is-flex-direction-column is-align-items-end menu-mobile"
        >
          
          <a
            v-if="!isCross"
            class="nav-marketplace secondary-font mr-2 mb-2"
            href="/#/cross"
            ><span @click="toggleMenu">CROSS</span></a
          >
          <a v-else class="nav-marketplace secondary-font mr-2 mb-2" href="/#/"
            ><span @click="toggleMenu">VAULT</span>
          </a>
          <a class="nav-marketplace secondary-font mr-2 mb-2" href="/#/artists"
            ><span @click="toggleMenu">ARTISTS</span></a
          >
          <a
       
        
          class="nav-marketplace secondary-font mr-2"
        href="https://log.fakewhale.xyz/" target=”_blank”
        >LOG</a
      >
          
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapStores } from "pinia";
import MyButton from "../components/MyButton.vue";
import checkViewport from "../mixins/checkViewport.js";
import { useUserStore } from "../stores/userStore";

// const Tezos = new TezosToolkit(process.env.VUE_APP_TOOKLIT_ENDPOINT);
// const beacon = new BeaconWallet({ name: "Fakewhale" });
// Tezos.setWalletProvider(beacon);

export default {
  name: "Navbar",
  mixins: [checkViewport],
  components: {
    MyButton,
  },
  data() {
    return {
      isCross: false,
      isArtists: false,
      isVault: true,
      showMenu: false,
      isOpen: false
    };
  },
  mounted() {
    this.detectNavBar();
  },
  watch: {
    $route() {
      this.detectNavBar();
    },
  },
  computed: {
    ...mapStores(useUserStore),
    addressShort() {
      const length = this.userStore.synced.length;

      return (
        this.userStore.synced.substring(0, 3) +
        "..." +
        this.userStore.synced.substring(length - 3, length)
      );
    },
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
      this.showMenu = !this.showMenu;
    },
    detectNavBar() {
      if (
        this.$route.name == "Marketplace" ||
        this.$route.name == "SingleNft"
      ) {
        this.isCross = true;
        this.isArtists = false;
        this.isVault = false;
      } else if (this.$route.name == "Artists") {
        this.isArtists = true;
        this.isCross = false;
        this.isVault = false;
      } else {
        this.isCross = false;
        this.isArtists = false;
        this.isVault = true;
      }
    },
  },
};
</script>

<style>
.left-section {
  display: flex;
}

.nav-marketplace {
  font-size: 13px;
  font-weight: 500 !important;
  text-decoration: none !important;
}

.right-section {
  display: flex;
  align-items: center;
}

.unsync-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nav-mobile > .rows {
  width: 100%;
}

.nav-mobile-row {
  display: flex;

  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .nav {
    display: flex !important;
  }

  .left-section {
    flex-direction: column;
  }

  .nav-sync-btn {
    margin-left: auto;
    min-width: 86px;
  }

  .nav-marketplace span {
    border-bottom: 0.5px white solid;
  }
  .unsync-btn {
    width: 30px;
  }
  .menu-toggle {
  display: inline-block;
  margin-top: 9px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  margin-bottom: 4px;
  transition: transform 0.3s ease;
}



.open .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}


.open .line:nth-child(2) {
  transform: rotate(-45deg) translate(-2px, 1px);
}

}
</style>
