var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.artist.artistId === undefined)?_c('div',{staticClass:"column",staticStyle:{"padding":"45vh 0","text-align":"center"}},[_vm._v(" Obtaining artist informations,"),_c('br'),_vm._v(" please wait.. ")]):_vm._e(),(_vm.artist.artistId !== undefined)?_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column artist"},[_c('div',{staticClass:"avatar-wrapper"},[(_vm.artist.avatar)?_c('div',{staticClass:"avatar",style:('background-image:url(' +
                _vm.artist.avatar +
                '); background-size:cover')},[_c('img',{attrs:{"src":"/check.svg","width":"40"}})]):_vm._e()]),_c('div',{staticClass:"links-wrapper"},[(_vm.artist.name)?_c('h1',{staticClass:"title is-3 secondary-font"},[_vm._v(_vm._s(_vm.artist.name))]):_vm._e(),_vm._v(" "+_vm._s(_vm.artist.artistId)+" ")])])])]),_c('div',{staticClass:"items"},_vm._l((_vm.artist.gallery),function(img){return _c('div',{key:img.img,staticClass:"item"},[_c('LazyComponent',[(
              img.isVideo === undefined ||
              (img.isVideo !== undefined && !img.isVideo)
            )?_c('img',{staticClass:"style-media",attrs:{"src":img.img}}):_vm._e(),(img.isVideo !== undefined && img.isVideo)?_c('video',{staticClass:"style-media",attrs:{"preload":"auto","loop":"","autoplay":"","muted":"","defaultMuted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":img.img,"type":"video/mp4"}}),_vm._v(" Your browser does not support HTML video. ")]):_vm._e(),_c('Loader',{attrs:{"slot":"placeholder"},slot:"placeholder"})],1),(img.link !== '#' && img.link !== '')?_c('a',{attrs:{"href":img.link,"target":"_blank"}},[(img.isMinted === undefined)?_c('span',[_vm._v("minted")]):_vm._e(),(img.isMinted !== undefined && img.isMinted === true)?_c('span',[_vm._v("minted")]):_vm._e(),(img.isMinted !== undefined && img.isMinted === false)?_c('span',[_vm._v("see")]):_vm._e(),_vm._v(" on "),(
              img.isMinted === undefined && img.link.indexOf('.com') !== -1
            )?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(img.link .split(".com")[0] .replace("https://", "") .replace("www.", "")))]):_vm._e(),(
              img.isMinted !== undefined &&
              img.isMinted === true &&
              img.link.indexOf('.com') !== -1
            )?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(img.link .split(".com")[0] .replace("https://", "") .replace("www.", "")))]):_vm._e(),(
              img.isMinted !== undefined &&
              img.isMinted === false &&
              img.link.indexOf('.com') !== -1
            )?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(img.link .split(".com")[0] .replace("https://", "") .replace("www.", ""))+".com")]):_vm._e(),(img.link.indexOf('.io') !== -1)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(img.link.split(".io")[0].replace("https://", "")))]):_vm._e(),(img.link.indexOf('.app') !== -1)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(img.link.split(".app")[0].replace("https://", "")))]):_vm._e(),(img.link.indexOf('.art') !== -1)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(img.link.split(".art")[0].replace("https://", "")))]):_vm._e(),(img.link.indexOf('.xyz') !== -1)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(img.link .split(".xyz")[0] .replace("https://", "") .replace("www.", "")))]):_vm._e()]):_vm._e()],1)}),0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[(_vm.artist.description)?_c('div',{staticClass:"description secondary-font",domProps:{"innerHTML":_vm._s(_vm.artist.description)}}):_vm._e(),_c('div',{staticClass:"columns",staticStyle:{"padding":"60px 0 100px 0","border-top":"1px solid #ddd"}},[(_vm.artist.web2 !== undefined && _vm.artist.web2.length > 0)?_c('div',{staticClass:"column",staticStyle:{"text-align":"left"}},[_vm._v(" web2:"),_c('br'),_vm._l((_vm.artist.web2),function(link){return _c('div',{key:link.link},[_c('a',{attrs:{"href":link.link,"target":"_blank"}},[_vm._v(_vm._s(link.type))])])})],2):_vm._e(),(_vm.artist.web3 !== undefined && _vm.artist.web3.length > 0)?_c('div',{staticClass:"column",staticStyle:{"text-align":"left"}},[_vm._v(" web3:"),_c('br'),_vm._l((_vm.artist.web3),function(link){return _c('div',{key:link.link},[_c('a',{attrs:{"href":link.link,"target":"_blank"}},[_vm._v(_vm._s(link.type))])])})],2):_vm._e()])])]),_vm._m(0)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-bottom":"100px"}},[_c('a',{staticClass:"item-modal-back-btn secondary-text secondary-font read m-auto",staticStyle:{"color":"#fff"},attrs:{"href":"/"}},[_vm._v("Back to vault")])])}]

export { render, staticRenderFns }