var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFull),expression:"!showFull"}],staticClass:"main-page",staticStyle:{"margin-top":"80px"}},[_c('div',{staticClass:"row  spacer-header",class:_vm.isMobile ? 'mb-5' : 'mb-6'},[_vm._m(0)]),(_vm.artists.length > 0)?_c('div',[_c('div',{staticClass:"columns is-mobile is-multiline mb-6 pb-6",class:{ 'pl-6 pr-6': !_vm.isMobile },staticStyle:{"text-align":"left","left":"0","text-transform":"capitalize"}},[_vm._l((_vm.visibleArtists),function(artist){return _c('div',{key:artist.artistId,staticClass:"column is-6-mobile is-4-tablet is-3-desktop name-artist"},[_c('a',{attrs:{"href":'/#/artist/' + artist.artistId}},[_vm._v(_vm._s(artist.name.toLowerCase()))])])}),(_vm.artists.length > 20 && _vm.isMobile)?_c('div',{staticClass:"column mt-5 is-12"},[_c('p',{staticClass:"has-text-left read",on:{"click":_vm.toggleShowMore}},[_vm._v(" "+_vm._s(_vm.showMore ? "Read less" : "Read more")+" ")])]):_vm._e()],2)]):_vm._e(),(_vm.artists.length > 0)?_c('hr',{staticStyle:{"margin":"40px 0"}}):_vm._e(),_c('div',{staticClass:"columns"},[(_vm.nfts.length > 0)?_c('div',{staticClass:"column nfts"},_vm._l((_vm.nfts),function(nft){return _c('div',{key:nft.tokenId,staticClass:"nft",class:{
              half:
                nft.from.artistId === 'tz1X1g6k6woqRWjqV1uC6Eq2y2ckxRhyoUap' ||
                nft.from.artistId === 'tz1i7fDupvRU8AWoQgPtqJvKivXnyvuvTLYN',
              'half-two-col':
                nft.from.artistId === 'tz1dEF5ZMTnBSkz2ZQPvpJjwyfeUUyGjeMtf',
            }},[(
                nft.metadata.artifactUri !== 'ipfs://Error' &&
                nft.hidden === false
              )?_c('div',[_c('div',{staticStyle:{"text-align":"center","position":"relative"}},[_c('LazyComponent',[(nft.isVideo)?_c('video',{staticClass:"video-home",staticStyle:{"width":"100%","cursor":"zoom-in"},attrs:{"preload":"auto","loop":"","autoplay":"","defaultMuted":"","playsinline":""},domProps:{"muted":_vm.videoIsMuted},on:{"click":function($event){return _vm.initFullscreenVideo(nft.metadata.fullscreenUri)}}},[_c('source',{attrs:{"src":nft.metadata.artifactUri
                          .replace('ipfs://', 'https://cdn.fakewhale.xyz/')
                          .replace(
                            'https://ipfs.yomi.digital/ipfs/',
                            'https://cdn.fakewhale.xyz/'
                          ) + '.mp4'}})]):_vm._e(),(nft.hasAudio !== undefined && nft.hasAudio === true)?_c('div',{staticClass:"video-audio",class:{ 'force-bottom': _vm.showFullVideo || _vm.showFull },on:{"click":_vm.toggleMuted}},[(!_vm.videoIsMuted)?_c('i',{staticClass:"mdi mdi-volume-high"}):_vm._e(),(_vm.videoIsMuted)?_c('i',{staticClass:"mdi mdi-volume-off"}):_vm._e()]):_vm._e(),_c('Loader',{attrs:{"slot":"placeholder"},slot:"placeholder"})],1)],1),(!nft.isVideo && !nft.isHtml)?_c('div',{staticClass:"nft-image",staticStyle:{"cursor":"zoom-in"},on:{"click":function($event){return _vm.initFullscreen(nft.metadata.fullscreenUri)}}},[_c('LazyComponent',[_c('img',{attrs:{"src":nft.metadata.artifactUri,"alt":nft.metadata.name,"transition-duration":"50","width":"100%"}}),_c('Loader',{attrs:{"slot":"placeholder"},slot:"placeholder"})],1)],1):_vm._e(),(nft.isHtml)?_c('div',[_c('iframe',{staticStyle:{"width":"100%","height":"75vh"},attrs:{"allowfullscreen":"","src":nft.metadata.artifactUri
                      .replace(
                        'https://cdn.fakewhale.xyz/',
                        'https://ipfs.yomi.digital/ipfs/'
                      )
                      .replace('.jpg', '')}})]):_vm._e(),_c('br'),_c('div',{staticClass:"nft-details"},[_c('div',{staticClass:"nft-name",staticStyle:{"text-align":"center"}},[_c('a',{attrs:{"href":'/#/artist/' + nft.from.artistId}},[_vm._v(_vm._s(nft.from.name))]),_c('span',{staticClass:"separator"},[_vm._v(" | ")]),_c('div',{staticClass:"mobile-space"}),_c('a',{attrs:{"href":'/#/vault/' + nft.tokenId}},[_vm._v(_vm._s(nft.metadata.name))]),_c('br'),_c('a',{attrs:{"href":_vm.explorerEndpoint.replace('{block}', nft.level),"target":"_blank"}},[_vm._v("BLOCK #"+_vm._s(nft.level))]),_c('span',{staticClass:"separator"},[_vm._v(" |")]),_c('div',{staticClass:"mobile-space"}),_vm._v(" NOT FOR SALE ")])])]):_vm._e()])}),0):_vm._e(),(_vm.nfts.length === 0)?_c('div',{staticClass:"column",staticStyle:{"padding":"37vh 0","text-align":"center"}},[_vm._v(" Loading collection,"),_c('br'),_vm._v(" please wait.. ")]):_vm._e()])]),(_vm.showFull)?_c('div',{staticClass:"fullscreen",style:('background-color: #000!important; background-image:url(/loading.gif); background-size:70px; width:100%; height:100vh'),on:{"click":function($event){return _vm.hideFullScreenImage()}}},[(_vm.showFullImage)?_c('div',{style:('background-image:url(' +
          _vm.fullscreenImg +
          '); background-size: contain;position:absolute; height:100vh; width:100%; top:0; left:0; background-position:center;background-repeat:no-repeat')}):_vm._e()]):_vm._e(),(_vm.showFullVideo)?_c('div',{staticClass:"fullscreen",staticStyle:{"background-color":"#000","background-image":"url(/loading.gif)","background-size":"70px","color":"#fff"},on:{"click":function($event){return _vm.hideFullScreenVideo()}}},[_c('video',{staticStyle:{"height":"100vh","width":"100%"},attrs:{"autoplay":"","loop":"","playsinline":""},domProps:{"muted":_vm.videoIsMuted}},[_c('source',{attrs:{"src":_vm.fullscreenVideo,"type":"video/mp4"}}),_vm._v(" Your browser does not support HTML video. ")])]):_vm._e(),_c('div',{staticStyle:{"position":"fixed","top":"0","left":"0","width":"100%","height":"100%","background":"#000","z-index":"99"},attrs:{"id":"black"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns marketplace-dots-row"},[_c('div',{staticClass:"column is-8 is-offset-2  testata"},[_c('p',{staticClass:"secondary-font"},[_vm._v("This permanent collection was born with the sole intent to capture an accurate snapshot of this defining historical period in digital art, "),_c('br'),_vm._v(" including who we consider to be the most influential creators. "),_c('br'),_vm._v("NO ARTWORKS ARE FOR SALE.")])])])}]

export { render, staticRenderFns }