<template>
  <div class="">
  
    <div v-show="!showFull" class="main-page" style="margin-top: 80px">
  
      <div :class="isMobile ? 'mb-5' : 'mb-6'" class="row  spacer-header">
  
      <div class="columns marketplace-dots-row">
        <div class="column is-8 is-offset-2  testata">
         <!--  <p class="secondary-font">All the artworks have been minted in our contract to establish the <span style="font-style: italic;">Fakewhale Permanent Collection</span>. <br> NO ARTWORKS ARE FOR SALE.</p> -->
          <p class="secondary-font">This permanent collection was born with the sole intent to capture an accurate snapshot of this defining historical period in digital art, <br /> including who we consider to be the most influential creators.
<br />NO ARTWORKS ARE FOR SALE.</p>
        </div>
      </div>
      <!-- <div class="columns marketplace-dots-row">
        <div class="column is-1">
          <div
            class="marketplace-dot left"
            :class="!isMobile ? '' : ' mobile'"
          ></div>
        </div>
        <div class="column is-10">
        
          <div class="marketplace-dot centered"></div>
        </div>
        <div class="column is-1">
          <div
            class="marketplace-dot"
            :class="!isMobile ? 'centered' : 'right mobile'"
          ></div>
        </div>
      </div> -->
    </div>
      <div v-if="artists.length > 0">
        <div
          :class="{ 'pl-6 pr-6': !isMobile }"
          class="columns is-mobile is-multiline mb-6 pb-6"
          style="text-align: left; left: 0; text-transform: capitalize"
        >
          <div
            v-for="artist in visibleArtists"
            :key="artist.artistId"
            class="column is-6-mobile is-4-tablet is-3-desktop name-artist"
          >
            <a :href="'/#/artist/' + artist.artistId">{{
              artist.name.toLowerCase()
            }}</a>
          </div>
          <div class="column mt-5 is-12" v-if="artists.length > 20 && isMobile">
            <p @click="toggleShowMore" class="has-text-left read">
              {{ showMore ? "Read less" : "Read more" }}
            </p>
          </div>
        </div>
      </div>
      <hr v-if="artists.length > 0" style="margin: 40px 0" />
      <div class="columns">
        <div class="column nfts" v-if="nfts.length > 0">
          <div
            class="nft"
            :class="{
              half:
                nft.from.artistId === 'tz1X1g6k6woqRWjqV1uC6Eq2y2ckxRhyoUap' ||
                nft.from.artistId === 'tz1i7fDupvRU8AWoQgPtqJvKivXnyvuvTLYN',
              'half-two-col':
                nft.from.artistId === 'tz1dEF5ZMTnBSkz2ZQPvpJjwyfeUUyGjeMtf',
            }"
            v-for="nft in nfts"
            v-bind:key="nft.tokenId"
          >
            <div
              v-if="
                nft.metadata.artifactUri !== 'ipfs://Error' &&
                nft.hidden === false
              "
            >
              <!--VIDEO-->
              <div style="text-align: center; position: relative">
                <LazyComponent>
                  <video
                    @click="initFullscreenVideo(nft.metadata.fullscreenUri)"
                    class="video-home"
                    preload="auto"
                    loop
                    autoplay
                    :muted="videoIsMuted"
                    defaultMuted
                    playsinline
                    v-if="nft.isVideo"
                    style="width: 100%; cursor: zoom-in"
                  >
                    <source
                      :src="
                        nft.metadata.artifactUri
                          .replace('ipfs://', 'https://cdn.fakewhale.xyz/')
                          .replace(
                            'https://ipfs.yomi.digital/ipfs/',
                            'https://cdn.fakewhale.xyz/'
                          ) + '.mp4'
                      "
                    />
                  </video>
                  <div
                    class="video-audio"
                    v-bind:class="{ 'force-bottom': showFullVideo || showFull }"
                    v-if="nft.hasAudio !== undefined && nft.hasAudio === true"
                    @click="toggleMuted"
                  >
                    <i v-if="!videoIsMuted" class="mdi mdi-volume-high"></i
                    ><i v-if="videoIsMuted" class="mdi mdi-volume-off"></i>
                  </div>
                  <Loader slot="placeholder" />
                </LazyComponent>
              </div>
              <!--VIDEO-->
              <!--IMAGE-->
              <div
                class="nft-image"
                v-if="!nft.isVideo && !nft.isHtml"
                @click="initFullscreen(nft.metadata.fullscreenUri)"
                style="cursor: zoom-in"
              >
                <LazyComponent>
                  <img
                    :src="nft.metadata.artifactUri"
                    :alt="nft.metadata.name"
                    transition-duration="50"
                    width="100%"
                  />
                  <Loader slot="placeholder" />
                </LazyComponent>
              </div>
              <!--IMAGE-->
              <!--HTML-->
              <div v-if="nft.isHtml">
                <iframe
                  allowfullscreen
                  :src="
                    nft.metadata.artifactUri
                      .replace(
                        'https://cdn.fakewhale.xyz/',
                        'https://ipfs.yomi.digital/ipfs/'
                      )
                      .replace('.jpg', '')
                  "
                  style="width: 100%; height: 75vh"
                ></iframe>
              </div>
              <!--HTML-->
              <br />
              <div class="nft-details">
                <div class="nft-name" style="text-align: center">
                  <a :href="'/#/artist/' + nft.from.artistId">{{
                    nft.from.name
                  }}</a>
                  <span class="separator"> | </span>
                  <div class="mobile-space"></div>
                  <a :href="'/#/vault/' + nft.tokenId">{{
                    nft.metadata.name
                  }}</a
                  ><br />
                  <a
                    :href="explorerEndpoint.replace('{block}', nft.level)"
                    target="_blank"
                    >BLOCK #{{ nft.level }}</a
                  >
                  <span class="separator"> |</span>
                  <div class="mobile-space"></div>
                  NOT FOR SALE
                </div>
              </div>
            </div>
          </div>
          <!-- nft -->
        </div>
        <div
          class="column"
          style="padding: 37vh 0; text-align: center"
          v-if="nfts.length === 0"
        >
          Loading collection,<br />
          please wait..
        </div>
      </div>
    </div>
    <!-- FULLSCREEN-->
    <div
      class="fullscreen"
      :style="'background-color: #000!important; background-image:url(/loading.gif); background-size:70px; width:100%; height:100vh'"
      v-if="showFull"
      @click="hideFullScreenImage()"
    >
      <div
        v-if="showFullImage"
        :style="
          'background-image:url(' +
          fullscreenImg +
          '); background-size: contain;position:absolute; height:100vh; width:100%; top:0; left:0; background-position:center;background-repeat:no-repeat'
        "
      ></div>
    </div>
    <div
      class="fullscreen"
      style="
        background-color: #000;
        background-image: url(/loading.gif);
        background-size: 70px;
        color: #fff;
      "
      v-if="showFullVideo"
      @click="hideFullScreenVideo()"
    >
      <video
        style="height: 100vh; width: 100%"
        autoplay
        :muted="videoIsMuted"
        loop
        playsinline
      >
        <source :src="fullscreenVideo" type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </div>
    <div
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 99;
      "
      id="black"
    ></div>
    <!-- FULLSCREEN-->
    </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import checkViewport from "../mixins/checkViewport.js";
import Loader from "@/components/Loader.vue";
import LazyComponent from "v-lazy-component";

export default {
  name: "Home",
  mixins: [checkViewport],
  data() {
    return {
      nfts: [],
      artists: [],
      axios: axios,
      black: false,
      explorerEndpoint: process.env.VUE_APP_EXPLORER_ENDPOINT,
      showFull: false,
      fullscreenImg: "",
      showFullVideo: false,
      showFullImage: false,
      fullscreenVideo: "",
      videoIsMuted: true,
      scroll: 0,
      visibleArtists: [],
    };
  },
  components: {
    LazyComponent,
    Loader,
  },
  async mounted() {
    const app = this;
    async function fetchAndSetArtists() {
      const artists = await app.axios.get(
        process.env.VUE_APP_LAMBDA_URL + "/artists"
      );
      const filteredArtists = artists.data.artists.filter(
        (artist) => !artist.isHidden && artist.name !== "turinglabs"
      );

      // Ordina gli artisti prima di assegnarli a app.artists
      filteredArtists.sort((a, b) => b.tokenBase - a.tokenBase);
      filteredArtists.sort((a, b) => b.priority - a.priority);

      return filteredArtists;
    }

    async function updateVisibleArtists() {
      if (app.showMore || !app.isMobile) {
        app.visibleArtists = await fetchAndSetArtists();
      } else {
        app.visibleArtists = app.artists.slice(0, 20);
      }
    }

    app.showMore = false;
    app.visibleArtists = [];

    fetchAndSetArtists().then((filteredArtists) => {
      app.artists = filteredArtists;
      updateVisibleArtists();
    });

    app.toggleShowMore = function () {
      app.showMore = !app.showMore;
      updateVisibleArtists();
    };
    const nfts = await app.axios.get(
      process.env.VUE_APP_LAMBDA_URL + "/nfts/all"
    );
    app.nfts = [];
    for (let k in nfts.data.nfts) {
      if (nfts.data.nfts[k].isVideo === undefined) {
        nfts.data.nfts[k].isVideo = false;
      }
      if (nfts.data.nfts[k].isGif === undefined) {
        nfts.data.nfts[k].isGif = false;
      }

      if (nfts.data.nfts[k].metadata.originalUri !== undefined) {
        nfts.data.nfts[k].metadata.originalUri = nfts.data.nfts[
          k
        ].metadata.originalUri.replace(
          "ipfs://",
          "https://ipfs.yomi.digital/ipfs/"
        );
        nfts.data.nfts[k].metadata.fullscreenUri = nfts.data.nfts[
          k
        ].metadata.originalUri.replace(
          "ipfs://",
          "https://ipfs.yomi.digital/ipfs/"
        );
      } else {
        nfts.data.nfts[k].metadata.fullscreenUri = nfts.data.nfts[
          k
        ].metadata.artifactUri.replace(
          "ipfs://",
          "https://ipfs.yomi.digital/ipfs/"
        );
      }

      nfts.data.nfts[k].metadata.artifactUri = nfts.data.nfts[
        k
      ].metadata.artifactUri.replace("ipfs://", "https://cdn.fakewhale.xyz/");
      if (nfts.data.nfts[k].isGif) {
        nfts.data.nfts[k].metadata.artifactUri += ".gif";
      } else if (!nfts.data.nfts[k].isVideo) {
        nfts.data.nfts[k].metadata.artifactUri += ".jpg";
      }

      nfts.data.nfts[k].hidden = false;
      if (nfts.data.nfts[k].from.isHidden !== undefined) {
        nfts.data.nfts[k].from.hidden = nfts.data.nfts[k].from.isHidden;
      }
      if (
        nfts.data.nfts[k].from.hidden !== undefined &&
        parseInt(nfts.data.nfts[k].from.hidden) > 0
      ) {
        if (new Date().getTime() < parseInt(nfts.data.nfts[k].from.hidden)) {
          nfts.data.nfts[k].hidden = true;
          console.log(
            "Will appear at " + new Date(nfts.data.nfts[k].from.hidden)
          );
        }
      } else if (
        nfts.data.nfts[k].from.hidden !== undefined &&
        nfts.data.nfts[k].from.hidden === true
      ) {
        nfts.data.nfts[k].hidden = true;
      }
      app.nfts.push(nfts.data.nfts[k]);
    }
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
      if (this.showMore) {
        // Mostra tutti gli artisti
        this.visibleArtists = this.artists;
      } else {
        // Mostra solo i primi 20 artisti
        this.visibleArtists = this.artists.slice(0, 20);
      }
    },
    toggleMuted() {
      const app = this;
      app.videoIsMuted = !app.videoIsMuted;
    },
    initFullscreen(img) {
      const app = this;
      app.fullscreenImg = img;
      app.showFull = true;
      setTimeout(function () {
        app.showFullImage = true;
      }, 100);
      document.querySelector("html").style.overflow = "hidden";
      app.scroll = $("html").scrollTop();
    },
    initFullscreenVideo(src) {
      const app = this;
      app.fullscreenVideo = src;
      app.showFullVideo = true;
      document.querySelector("html").style.overflow = "hidden";
      app.scroll = $("html").scrollTop();
    },
    hideFullScreenImage() {
      const app = this;
      app.showFull = false;
      app.showFullImage = false;
      $("#black").css("display", "block");
      setTimeout(function () {
        $("html").scrollTop(app.scroll);
        document.querySelector("html").style.overflow = "inherit";
        $("#black").css("display", "none");
      }, 1);
    },
    hideFullScreenVideo() {
      const app = this;
      app.showFullVideo = false;
      $("#black").css("display", "block");
      setTimeout(function () {
        $("html").scrollTop(app.scroll);
        document.querySelector("html").style.overflow = "inherit";
        $("#black").css("display", "none");
      }, 1);
    },
  },
};
</script>
