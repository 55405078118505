var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"marketplace-box"},[_c('div',{staticClass:"rows"},[_c('div',{staticClass:"row spacer-header",class:_vm.isMobile ? 'mb-5' : 'mb-6'},[_vm._m(0)]),(_vm.isMobile && _vm.nftsFetchStatus == 'success')?_c('div',{staticClass:"row mb-4"},[(!_vm.filtersOpen)?_c('button',{staticClass:"filter-toggle",on:{"click":function($event){_vm.filtersOpen = true}}},[_c('img',{attrs:{"src":"/filter.svg"}}),_c('span',{staticClass:"ml-1 mb-1"},[_vm._v("Filters")])]):_vm._e(),(_vm.filtersOpen)?_c('div',{staticClass:"marketplace-filter-open-ctn px-3"},[_c('span',{staticClass:"secondary-font"},[_vm._v("Filters")]),_c('icon-button',{on:{"iconClicked":function($event){_vm.filtersOpen = false}}},[_c('img',{attrs:{"src":"/close.svg"}})])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"columns"},[(
              _vm.nftsFetchStatus == 'pending' || _vm.artistsFetchStatus == 'pending'
            )?_c('div',{staticClass:"marketplace-centered-ctn"},[_c('div',[_vm._v("Reading blockchain, please wait..")])]):(_vm.nftsFetchStatus == 'failure')?_c('div',[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),((!_vm.isMobile || _vm.filtersOpen) && _vm.nftsFetchStatus == 'success')?_c('div',{staticClass:"column is-2 marketplace-left-column"},[_c('div',{staticClass:"marketplace-left-column-sticked pb-2"},[_vm._m(1),_c('div',{staticClass:"marketplace-dot left"}),_c('filters',{attrs:{"listing":_vm.listing,"inputActiveFilters":_vm.activeFilters},on:{"filterSelected":_vm.onFilterChange}})],1)]):_vm._e(),_c('div',{staticClass:"column is-10",class:_vm.filtersOpen || _vm.nftsFetchStatus != 'success' ? 'no-display' : ''},[(_vm.filteredListing.length > 0)?_c('div',[_c('div',{staticClass:"columns is-multiline marketplace-nfts-ctn",class:_vm.isMobile ? '' : 'p-5'},_vm._l((_vm.paginatedListing),function(listingItem){return _c('listing-card',{key:listingItem.objktUrl,staticClass:"marketplace-listing-card",attrs:{"listingItem":listingItem},on:{"cardClicked":_vm.onSelectNft}})}),1),_c('div',{staticClass:"marketplace-pagination-ctn px-5 mt-6"},[_c('icon-button',{staticClass:"marketplace-pagination-left",class:_vm.canMovePaginationBack
                      ? ''
                      : 'marketplace-disabled-pagination',on:{"iconClicked":_vm.paginationBack}},[_c('img',{attrs:{"src":"/arrow.svg"}})]),_c('icon-button',{class:_vm.canMovePaginationForward
                      ? ''
                      : 'marketplace-disabled-pagination',on:{"iconClicked":_vm.paginationForward}},[_c('img',{attrs:{"src":"/arrow.svg"}})])],1)]):(
                _vm.nftsFetchStatus == 'success' || _vm.nftsFetchStatus == 'not_sent'
              )?_c('div',{staticStyle:{"text-align":"left !important"}},[_vm._v(" No NFTs available... ")]):_vm._e()])])]),_c('div',{staticClass:"row mt-6 pt-6 spacer-footer",class:_vm.filtersOpen ? 'no-display' : ''})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns marketplace-dots-row"},[_c('div',{staticClass:"column is-8 is-offset-2  testata"},[_c('p',{staticClass:"secondary-font"},[_vm._v("Curated Art Sales.")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"artworks-stats-ctn"},[_c('h6',{staticClass:"has-text-left secondary-font mb-4"},[_vm._v("Artworks")])])}]

export { render, staticRenderFns }